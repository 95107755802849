<template>
  <div id="medidas">
    <MenuBase
      :tipo="'medidas'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-tape-measure'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <Medida :modo="modoMedida" :dialogME.sync="dialogMedida" :nmedida.sync="this.campoOpcion" :origen.sync="origen"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Medida from '@/views/medida.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Medida
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Medidas',
    dialog: false,
    tituloOpciones: 'Opciones medida: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoMedida: 'NUEVA',
    dialogMedida: false,
    origen: 'tabla'
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'NOTAS', align: 'start', value: 'notas' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVA MEDIDA', color: 'primary', accion: 'nuevaMedida' }
      ]
      this.opciones = [
        { id: 1, nombre: 'VER/EDITAR MEDIDA', color: 'primary', accion: 'editarMedida' }
        // { id: 2, nombre: 'QUITAR MEDIDA', color: 'error', accion: 'quitarMedida' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevaMedida') {
        this.modoMedida = 'NUEVA'
        this.dialogMedida = true
      } else if (accion === 'editarMedida') {
        this.modoMedida = 'EDITAR'
        this.dialogMedida = true
      } else if (accion === 'quitarMedida') {
        alert('Se quitará ésta medida como parámetro seleccionable')
      }
    },
    async cargarItems () {
      this.cargarTabla('medidas/todo', 'cambiarTabla', 'cargarTablaMedidas')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('medidas') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
